import loadable from "@loadable/component"; // react 楼层按需加载

const routes = [
  {
    path: "/sign",
    component: loadable(() => import("@pages/sign/index")),
  },
  {
    path: "/",
    component: loadable(() => import("@components/layout")),
    children: [
      {
        path: "",
        component: loadable(() => import("@pages/home/index")),
      },
      {
        path: "home",
        component: loadable(() => import("@pages/home/index")),
      },
      {
        path: "cooperation",
        component: loadable(() => import("@pages/cooperation/index")),
      },
      {
        path: "intro/mpxcx",
        component: loadable(() => import("@pages/intro/mpxcx/index")),
      },
      {
        path: "intro/zmxcx",
        component: loadable(() => import("@pages/intro/zmxcx/index")),
      },
      {
        path: "intro/jxc",
        component: loadable(() => import("@pages/intro/jxc/index")),
      },
      {
        path: "join",
        component: loadable(() => import("@pages/join/index")),
      },
      {
        path: "about",
        component: loadable(() => import("@pages/about/index")),
      },
    ],
  },
];

export default routes;
