import 'react-app-polyfill/ie9';
import React from "react";
import ReactDOM from "react-dom";
import AppRouter from "./router";
import "./index.less";
// 由于 antd 组件的默认文案是英文，所以需要修改为中文
import { ConfigProvider } from "antd";
import zhCN from "antd/es/locale/zh_CN";
import "moment/locale/zh-cn";

ReactDOM.render(
  <ConfigProvider locale={zhCN}>
    <AppRouter />
  </ConfigProvider>,
  document.getElementById("root")
);
